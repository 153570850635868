import React, { useRef, useState } from "react"
import Photo from "./Photo"
import { useSpring, animated } from "react-spring"
import { useGesture } from "react-use-gesture"
import { useInterval, computeInnerSize } from "../../utils"

export default function PhotoWall({ photos, id }) {
  const [width, setWidth] = useState(500)
  const [width2, setWidth2] = useState(500)
  const ref = useRef()
  const ref2 = useRef()
  const [{ x, hover }, set] = useSpring(() => ({ x: 0, hover: 0 }))
  const [{ x2, hover2 }, set2] = useSpring(() => ({ x2: 100, hover2: 0 }))
  useInterval(() => {
    if (ref.current)
      setWidth(computeInnerSize(ref.current) - (window.innerWidth * 2) / 3)
    if (ref2.current)
      setWidth2(computeInnerSize(ref2.current) - (window.innerWidth * 2) / 3)
  }, 5000)
  useInterval(() => {
    if (hover.value === 0 && x.value > -width) set({ x: x.value - 1 })
    if (hover2.value === 0 && x2.value > -width) set2({ x2: x2.value - 0.7 })
  }, 50)
  const bind = useGesture(
    {
      onDrag: ({ offset: [mx] }) => {
        set({ x: mx, hover: 1 })
      },
      onHover: ({ active }) => {
        set({ hover: active ? 1 : 0 })
      },
    },
    {
      drag: {
        filterTaps: true,
        bounds: { left: -width, right: 0 },
        rubberband: true,
        eventOptions: { passive: false, capture: true },
      },
    }
  )
  const bind2 = useGesture(
    {
      onDrag: ({ offset: [mx] }) => {
        set2({ x2: mx, hover2: 1 })
      },
      onHover: ({ active }) => {
        set2({ hover2: active ? 1 : 0 })
      },
    },
    {
      drag: {
        filterTaps: true,
        bounds: { left: -width2, right: 0 },
        rubberband: true,
        eventOptions: { passive: false, capture: true },
      },
    }
  )

  if (!photos.length) return null

  return (
    <div id={id}>
      <animated.div
        ref={ref}
        {...bind()}
        className="relative flex justify-start items-start -mx-2 lg:-mx-5 my-5 h-screen/3 lg:h-56 cursor-move"
        style={{
          transform: x.interpolate(x => `translateX(${x}px)`),
          width: "fit-content",
          touchAction: "none",
        }}
      >
        {[...photos]
          .slice(
            0,
            photos.length > 9 ? Math.round(photos.length / 2) : photos.length
          )
          .map(photo => (
            <Photo
              key={photo.url}
              image={photo}
              className="m-2 flex-shrink-0 select-none h-full"
            />
          ))}
      </animated.div>
      {photos.length > 9 && (
        <animated.div
          ref={ref2}
          {...bind2()}
          className="relative flex justify-start items-start -mx-2 lg:-mx-5 my-5 h-screen/3 lg:h-56 cursor-move"
          style={{
            transform: x2.interpolate(x => `translateX(${x}px)`),
            width: "fit-content",
            touchAction: "none",
          }}
        >
          {[...photos]
            .slice(Math.round(photos.length / 2), photos.length)
            .map(photo => (
              <Photo
                key={photo.url}
                image={photo}
                className="m-2 lg:m-5 flex-shrink-0 select-none h-full"
              />
            ))}
        </animated.div>
      )}
    </div>
  )
}
