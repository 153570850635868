import React, { useRef, useState } from "react"
import moment from "moment"
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa"
import { useSpring, animated } from "react-spring"
import { useGesture } from "react-use-gesture"
import { useInterval, computeInnerSize } from "../../utils"
import ReactMarkdown from "react-markdown"

export default function Reviews({ reviews, id }) {
  const [width, setWidth] = useState(500)
  const ref = useRef()
  const [{ x, hover }, set] = useSpring(() => ({ x: 0, hover: 0 }))
  useInterval(() => {
    if (ref.current)
      setWidth(computeInnerSize(ref.current) - (window.innerWidth * 2) / 3)
  }, 5000)
  useInterval(() => {
    if (hover.value === 0 && x.value > -width) set({ x: x.value - 3 })
  }, 50)
  const bind = useGesture(
    {
      onDrag: ({ offset: [mx] }) => {
        set({ x: mx, hover: 1 })
      },
      onHover: ({ active }) => {
        set({ hover: active ? 1 : 0 })
      },
    },
    {
      drag: {
        filterTaps: true,
        bounds: { left: -width, right: 0 },
        rubberband: true,
        eventOptions: { passive: false, capture: true },
      },
    }
  )
  if (!reviews.length) return null
  const sizes = {
    small: "w-1/2+sm lg:w-1/4+m",
    default: "w-1/2+sm lg:w-1/3+m",
    large: "lg:w-1/2+m",
    full: "lg:w-full",
  }
  return (
    <animated.div
      id={id}
      ref={ref}
      {...bind()}
      className="relative flex justify-start -mx-2 lg:-mx-5 my-5 cursor-move"
      style={{
        transform: x.interpolate(x => `translateX(${x}px)`),
        width: "fit-content",
        touchAction: "none",
      }}
    >
      {reviews.map(review => (
        <div
          key={review.link}
          className={`max-w-xs lg:max-w-none m-2 lg:m-5 rounded-3xl bg-white px-6 py-4 overflow-hidden flex-shrink-0 select-none ${sizes["default"]}`}
        >
          <div className="w-full text-base font-bold leading-tight lg:pb-1 mb-1 flex justify-start items-center">
            {review.title}
          </div>
          <div className="absolute flex -mt-15">
            {Array(Math.floor(review.score))
              .fill(true)
              .map(() => (
                <FaStar className="text-yellow w-3 h-3" />
              ))}
            {review.score % 1 > 0 && (
              <FaStarHalfAlt className="text-yellow w-3 h-3" />
            )}
            {Array(Math.max(5 - Math.ceil(review.score), 0))
              .fill(true)
              .map(() => (
                <FaRegStar className="text-yellow w-3 h-3" />
              ))}
          </div>
          <ReactMarkdown className="text-sm lg:text-base font-light leading-tight mt-5">
            {review.review.data.review}
          </ReactMarkdown>
          <div className="flex flex-col italic text-base items-start mt-4">
            <div className="opacity-50">
              {review.name} - {moment(review.date).format("DD/MM/YYYY")}
            </div>
            {review.url.match(/trustpilot/) && (
              <a href={review.url} target="_blank" rel="noopener noreferrer">
                <img
                  className="w-20"
                  src="https://cdn.trustpilot.net/brand-assets/1.0.3/logo-black.svg"
                  alt="logo Trustpilot"
                />
              </a>
            )}
            {review.url.match(/mariages\.net/) && (
              <a href={review.url} target="_blank" rel="noopener noreferrer">
                <img
                  className="w-32"
                  src="https://www.mariages.net/assets/img/logos/gen_logoHeader.svg"
                  alt="logo Mariage.net"
                />
              </a>
            )}
          </div>
        </div>
      ))}
    </animated.div>
  )
}
