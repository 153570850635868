import React, { useState } from "react"

export default function Help({ help, index, id, style, className }) {
  const [isActive, setIsActive] = useState(false)

  return (
    <div id={id} style={style} className="relative m-3 lg:w-9/12">
      <div
        className={`relative max-w-xs lg:max-w-none lg:ml-5 ml-2 rounded-3xl py-2 px-4 lg:px-6 z-10 bg-blue`}
      >
        <dt className={`flex justify-start leading-tight my-4`}>
          <button
            className={`text-white font-bold text-base hover-no-italic flex justify-between w-full no-outline`}
            onClick={() => setIsActive(!isActive)}
          >
            <span className={``}>{help.question}</span>
            <span className={``}>
              {!isActive && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v12m6-6H6"
                  ></path>
                </svg>
              )}
              {isActive && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  class="ob dxv"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M18 12H6"
                  ></path>
                </svg>
              )}
            </span>
          </button>
        </dt>
        {isActive && (
          <dd className="flex lg:justify-start text-sm font-light leading-tight lg:text-base my-4">
            <p className={`text-white text-center`}>
              {help.answer.data.answer}
            </p>
          </dd>
        )}
      </div>
    </div>
  )
}
