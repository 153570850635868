import React from "react"
import ReactMarkdown from "react-markdown"

function A({ children, href }) {
  return (
    <a
      href={href}
      style={{
        fontWeight: 600,
        fontFamily: "Montserrat",
        fontStyle: "italic",
        textDecoration: "underline",
      }}
    >
      {children}
    </a>
  )
}

export default function Text({ children, style, className, id }) {
  return (
    <div
      id={id}
      style={style}
      className={`my-6 text-base lg:text-lg font-light leading-snug ${className}`}
    >
      <ReactMarkdown components={{ a: A }} children={children} />
    </div>
  )
}
