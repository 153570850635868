import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useInterval } from "../utils"
import Button from "./UI/Button"

const linksClass =
  "opacity-75 hover:opacity-100 max-w-md text-sm truncate text-white"

export default function Footer({ locale, type }) {
  const data = useStaticQuery(graphql`
    {
      footer_fr: allStrapiFooter(filter: { locale: { eq: "fr" } }) {
        nodes {
          pages {
            path
            title
            locale
            type
          }
          products {
            id
            path
            name
            locale
          }
          bottomLinks {
            label
            url
          }
          OfficesLinks {
            label
            url
          }
        }
      }
      footer_en: allStrapiFooter(filter: { locale: { eq: "en" } }) {
        nodes {
          pages {
            path
            title
            locale
            type
          }
          products {
            id
            path
            name
            locale
          }
          bottomLinks {
            label
            url
          }
          OfficesLinks {
            label
            url
          }
        }
      }
      teams: allStrapiTeam {
        nodes {
          name
          sales
          quote {
            data {
              quote
            }
          }
          photo {
            url
            ext
          }
        }
      }
      variable_fr: strapiVariable(locale: { eq: "fr" }) {
        email
        phone
        linkedin
        instagram
        facebook
        youtube
        pinterest
        tiktok
      }
      variable_en: strapiVariable(locale: { eq: "en" }) {
        email
        phone
        linkedin
        instagram
        facebook
        youtube
        pinterest
        tiktok
      }
    }
  `)

  const { footer_fr, footer_en, variable_fr, variable_en, teams } = data
  const [footer, variable] =
    locale === "fr" ? [footer_fr, variable_fr] : [footer_en, variable_en]

  const backgroundColor = type === "BTC" ? "bg-pink" : "bg-blue"
  const [salesId, setSalesId] = useState(0)
  const sales = teams.nodes.filter(t => t.sales)

  useInterval(() => {
    setSalesId((salesId + 1) % sales.length)
  }, 6500)

  return (
    <>
      <div
        className={`w-full py-8 lg:py-16 text-black shadow z-10 ${backgroundColor}`}
      >
        <div className="h-full flex flex-col justify-center items-center text-base lg:text-lg mx-auto w-5/6">
          <img
            src="/logo-blanc.png"
            className="w-28 h-16 lg:mb-5"
            alt="logo wizito"
          />
          <div className="flex flex-col-reverse lg:flex-row lg:justify-between w-full font-light text-base mt-1">
            <div>
              <div className="flex items-center flex-wrap justify-center lg:justify-start">
                <>
                  <div className={`${linksClass} font-thin`}>
                    <span className={`font-bold`}>Tel: </span> {variable.phone}
                  </div>
                  <span className="mx-3 text-white">|</span>
                  <div className={`${linksClass} font-thin`}>
                    <span className={`font-bold`}>Email: </span>{" "}
                    {variable.email}
                  </div>
                </>
              </div>
              <div className="flex items-center flex-wrap justify-center lg:justify-start">
                {footer.nodes[0].bottomLinks.map(({ label, url }, index) => (
                  <>
                    <a href={url} className={`${linksClass} font-bold`}>
                      {label}
                    </a>
                    {index !== 4 && <div className="mx-3 text-white">|</div>}
                  </>
                ))}
              </div>
              <div className="text-center lg:flex items-center font-thin">
                {footer.nodes[0].OfficesLinks.map(({ label, url }, index) => (
                  <>
                    <a href={url} className={linksClass}>
                      {label}
                    </a>
                    {index !== 5 && (
                      <div className="invisible lg:visible -my-2 lg:mx-3 text-white ">
                        |
                      </div>
                    )}
                  </>
                ))}
              </div>
              <div className="flex justify-center lg:justify-start items-center mt-4">
                <div className={"font-light text-xs text-white"}>
                  ©{new Date().getFullYear()} - Wizito.{" "}
                  {locale === "fr"
                    ? "Tous droits réservés"
                    : "All rights reserved"}
                </div>
              </div>
            </div>
            <div className="flex justify-center my-3 lg:mx-8 w-full lg:w-auto">
              <Button
                variant="footer"
                href={variable.facebook}
                className="mr-2"
              >
                <img src="/facebook.svg" alt="facebook" />
              </Button>
              <Button
                variant="footer"
                href={variable.instagram}
                className="mr-2"
              >
                <img src="/instagram.svg" alt="instagram" />
              </Button>
              <Button
                variant="footer"
                href={variable.linkedin}
                className="mr-2"
              >
                <img src="/linkedin.svg" alt="linkedin" />
              </Button>
              <Button variant="footer" href={variable.tiktok} className="mr-2">
                <img src="/tiktok.svg" alt="tiktok" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
