import React from "react"
import Photo from "./Photo"
import { animated } from "react-spring"

export default function ImageWall({ photos, id }) {
  return (
    <div id={id} className="my-2 overflow-hidden lg:overflow-visible">
      <animated.div className="relative lg:flex justify-evenly -mx-2 lg:-mx-5 my-2 h-screen/4 lg:h-64 cursor-move">
        {[...photos]
          .slice(
            0,
            photos.length > 9 ? Math.round(photos.length / 2) : photos.length
          )
          .map(photo => (
            <Photo
              key={photo.url}
              image={photo}
              className="m-2 select-none h-full"
            />
          ))}
      </animated.div>
    </div>
  )
}
