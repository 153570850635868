import React, { useEffect, useRef, useState } from "react"

export default function Iframe({ id, url, ratio, size }) {
  const ref = useRef()
  const [height, setHeight] = useState(300)
  useEffect(() => {
    if (ref.current)
      setHeight(ref.current.getBoundingClientRect().width / ratio)
  }, [ref])
  return (
    <div
      id={id}
      ref={ref}
      className={`${
        size === "full" ? "w-full" : "w-full lg:w-1/2"
      } bg-white rounded shadow-md p-2 my-12 mx-auto`}
    >
      <iframe
        src={url}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        className={`w-full`}
        style={{ height }}
        allowfullscreen
      />
    </div>
  )
}
