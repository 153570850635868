import React, { useState } from 'react'
import Cookies from 'js-cookie'
import Button from './UI/Button'
import { useTimeoutFn } from 'react-use'

export default function CookieBanner({ locale }) {
  const [wizitoCookie, setWizitoCookie] = useState(true)

  useTimeoutFn(() => setWizitoCookie(Cookies.get('wizitocookie')), 2000)

  if (wizitoCookie) return <div key="cookiebanner" />
  return (
    <div key="cookiebanner" className="fixed bottom-0 md:bottom-4 flex justify-center items-center z-10 w-full">
      <div className="flex max-w-2xl flex-row justify-center items-center text-left py-2 px-4 lg:py-4 lg:px-6 bg-white shadow-xl rounded">
        <div className="text-xs md:text-base leading-tight -mr-6 md:mr-2">
          {locale === 'fr'
            ? 'En cliquant sur « Accepter », vous acceptez le stockage de cookies sur votre appareil pour améliorer la navigation sur le site.'
            : 'By clicking on «Accepts», you accept the storage of cookies on your device to improve site navigation'
          }
        </div>
        <Button onClick={() => setWizitoCookie(() => Cookies.set('wizitocookie', 'true', { expires: 30 }))} className="scale-75 transform origin-right lg:transform-none">
          {locale === 'fr' ? 'Accepter' : 'Accept'}
        </Button>
      </div>
    </div>
  )       
}