import React from "react"

export default function SideBySide({
  left,
  right,
  id,
  className,
  background,
  photos,
  layout,
  style,
  type,
}) {
  return (
    <div
      id={id}
      className={`relative flex justify-center text-black ${className} ${
        background && "text-white"
      }  ${
        layout === "none"
          ? "lg:text-left"
          : `${!style && "rounded-3xl"} bg-gray-100 my-6`
      }`}
    >
      {background && (
        <div
          className={`absolute lg:block w-screen h-full ${type}`}
          style={{ zIndex: -1, clipPath: "url(#backgroundClip)" }}
        />
      )}

      <div
        className={`flex flex-col lg:flex-row w-full ${
          layout === "photo_right" && "flex-col-reverse"
        }`}
      >
        <div className={`flex w-full ${layout !== "none" && `lg:w-1/2`}`}>
          {left}
        </div>
        {layout !== "none" && <div className={`w-full lg:w-1/2`}>{right}</div>}
      </div>
    </div>
  )
}
