import React from "react"

export default function Spacer({ size = "default", id }) {
  const sizes = {
    small: "my-6 lg:my-10",
    default: "my-10 lg:my-20",
    large: "my-20 lg:my-40",
  }
  return <div id={id} className={sizes[size]} />
}
