import React, { useState } from "react"
import { FiArrowRight, FiArrowLeft } from "react-icons/fi"
import Portal from "./Portal"
import { useKey, useTimeoutFn } from "react-use"
import { getImageBySize, useInterval } from "../../utils"

export default function Photos({
  images,
  className,
  style,
  caption,
  large = false,
  id,
  parentId,
  active,
}) {
  const [zoom, setZoom] = useState(false)
  const [load, setLoad] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  useTimeoutFn(() => setLoad(true), Math.random() * 1500 + 750)
  useKey("Escape", () => setZoom(false))

  useInterval(() => {
    nextSlide()
  }, 6000)

  const nextSlide = () => {
    setActiveIndex(prevIndex =>
      prevIndex === images?.length - 1 ? 0 : prevIndex + 1
    )
  }

  const prevSlice = () => {
    setActiveIndex(prevIndex =>
      prevIndex === 0 ? images?.length - 1 : prevIndex - 1
    )
  }

  if (!images) return null

  return (
    <>
      <div
        id={id}
        onKeyDown={() => setZoom(!zoom)}
        onClick={() => setZoom(!zoom)}
        className={`relative flex cursor-pointer overflow-hidden h-full w-full ${className}`}
        role="button"
        tabIndex="0"
        style={{ ...style }}
      >
        {active && (
          <div className="absolute inset-x-0 bottom-0 flex justify-between items-center w-full mb-10  z-10">
            <button className="ml-10" onClick={prevSlice}>
              <FiArrowLeft className="text-white lg:h-10 lg:w-10" />
            </button>
            <button className="mr-10" onClick={nextSlide}>
              <FiArrowRight className="text-white lg:h-10 lg:w-10" />
            </button>
          </div>
        )}

        {load && images[activeIndex].ext === ".mp4" && (
          <video
            src={getImageBySize(images[activeIndex])}
            className={`w-full h-full pointer-events-none rounded object-cover ${
              large && "w-full"
            }`}
            autoPlay
            loop
            muted
            playsInline
          />
        )}
        {load && images[activeIndex].ext !== ".mp4" && (
          <img
            className={`w-full h-full pointer-events-none bg-cover ${className} ${
              large && "w-full"
            }`}
            style={{
              backgroundImage: `url(${getImageBySize(
                images[activeIndex],
                "large"
              )})`,
              transition: "all 1s linear",
              ...style,
              //maxHeight: "50vh",
            }}
          />
        )}
      </div>
      {zoom && !active && (
        <Portal>
          <div
            onKeyDown={() => setZoom(!zoom)}
            onClick={() => setZoom(!zoom)}
            role="button"
            tabIndex="0"
            className="fixed top-0 left-0 w-screen h-screen flex justify-center p-4 lg:p-10 items-center cursor-pointer z-20 bg-black bg-opacity-75"
          >
            {images[activeIndex].ext === ".mp4" && (
              <video
                src={getImageBySize(images[activeIndex], "large")}
                className="max-h-full"
                autoPlay
                loop
                muted
                playsInline
              />
            )}
            {images[activeIndex].ext !== ".mp4" && (
              <img
                className={`max-h-full pointer-events-none bg-cover`}
                src={getImageBySize(images[activeIndex], "large")}
              />
            )}
          </div>
        </Portal>
      )}
    </>
  )
}
