import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FiCornerDownRight } from "react-icons/fi"
import { getImageBySize, localePath } from "../../utils"
import Portal from "./Portal"
import { useKey, useTimeoutFn } from "react-use"

const path =
  typeof window !== `undefined` ? window.location.pathname.replace("/", "") : ""

export default function Photo({
  image,
  className,
  style,
  caption,
  allowTransparency = false,
  large = false,
  id,
}) {
  const data = useStaticQuery(graphql`
    {
      products: allStrapiProduct {
        nodes {
          name
          path
          locale
          photos {
            url
            formats {
              large {
                url
              }
              medium {
                url
              }
              small {
                url
              }
              thumbnail {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { products } = data
  const [zoom, setZoom] = useState(false)
  const [matchedProducts, setProducts] = useState(
    image
      ? products.nodes.filter(p =>
          p.photos.map(ph => ph.url).includes(image.url)
        )
      : []
  )

  const [load, setLoad] = useState(false)
  useTimeoutFn(() => setLoad(true), Math.random() * 1500 + 750)
  useKey("Escape", () => setZoom(false))
  useEffect(() => {
    if (image)
      setProducts(
        products.nodes
          .filter(p => p.photos.map(ph => ph.url).includes(image.url))
          .filter(p => p.path !== path)
      )
  }, [])

  if (!image) return null
  const url = getImageBySize(image, large ? "large" : "medium") || image
  const ext = image.ext || ".jpeg"
  if ((ext === ".png" || ext === ".gif") && allowTransparency)
    return (
      <div
        className={`relative flex items-center justify-center cursor-pointer overflow-hidden ${className}`}
        style={{ maxHeight: "50vh", ...style }}
      >
        {load && (
          <img
            src={url}
            alt={image.alternativeText}
            className={`w-full pointer-events-none ${className}`}
            style={{
              maxHeight: "50vh",
              filter:
                "drop-shadow(0 4px 6px rgba(0, 0, 0, 0.05)) drop-shadow(0 2px 4px rgba(0, 0, 0, 0.05))",
            }}
          />
        )}
      </div>
    )

  return (
    <>
      <div
        id={id}
        className={`relative flex items-center justify-center cursor-pointer overflow-hidden my-2 ${className} `}
        style={{ maxHeight: "50vh" }}
        onClick={() => setZoom(!zoom)}
        onKeyDown={() => setZoom(!zoom)}
        role="button"
        tabIndex="0"
      >
        {load && ext === ".mp4" && (
          <video
            src={url}
            className={`max-w-full max-h-full h-full pointer-events-none rounded ${
              large && "w-full"
            }`}
            autoPlay
            loop
            muted
            playsInline
            style={{ maxHeight: "50vh" }}
          />
        )}
        {load && ext !== ".mp4" && (
          <img
            alt={image.alternativeText}
            src={url}
            className={`max-w-full max-h-full pointer-events-none ${className} ${
              large && "w-full"
            }`}
            style={{
              ...style,
              maxHeight: "50vh",
            }}
          />
        )}

        <div
          className="absolute left-0 mt-3 font-light text-sm text-blue-dark opacity-50"
          style={{ top: "100%" }}
        >
          {matchedProducts.map(p => (
            <div key={p.path} className="flex items-center">
              <FiCornerDownRight className="mr-1 h-4 w-4 stroke-2" />
              <a
                href={localePath(p)}
                className="hover:underline"
                onClick={() => setTimeout(() => setZoom(false), 0)}
              >
                Notre {p.name}
              </a>
            </div>
          ))}
        </div>
      </div>
      {caption && (
        <div className="mt-3 font-light text-sm text-blue-dark opacity-50 text-center italic mb-5">
          {caption}
        </div>
      )}
      {zoom && (
        <Portal>
          <div
            onClick={() => setZoom(!zoom)}
            onKeyDown={() => setZoom(!zoom)}
            className="fixed top-0 left-0 w-screen h-screen flex justify-center p-4 lg:p-10 items-center cursor-pointer z-20 bg-black bg-opacity-75"
            role="button"
            tabIndex="0"
          >
            {ext === ".mp4" && (
              <video
                src={url}
                className="rounded border-8 border-white max-h-full"
                autoPlay
                loop
                muted
                playsInline
              />
            )}
            {ext !== ".mp4" && (
              <img
                alt={image.alternativeText}
                src={getImageBySize(image, "large")}
                className="rounded border-8 border-white max-h-full"
              />
            )}
          </div>
        </Portal>
      )}
    </>
  )
}
