import React, { useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useSpring, animated } from "react-spring"
import { useGesture } from "react-use-gesture"
import { useInterval, computeInnerSize } from "../utils"

export default function ClientsWall({ className, id }) {
  const data = useStaticQuery(graphql`
    {
      variable: strapiVariable {
        clients {
          url
        }
      }
    }
  `)
  const { variable } = data
  const [width, setWidth] = useState(500)
  const ref = useRef()
  const [{ x, hover }, set] = useSpring(() => ({ x: 800, hover: 0 }))
  useInterval(() => {
    if (ref.current)
      setWidth(computeInnerSize(ref.current) - (window.innerWidth * 2) / 3)
  }, 5000)
  useInterval(() => {
    if (hover.value === 0 && x.value > -width) set({ x: x.value - 1 })
  }, 50)
  const bind = useGesture(
    {
      onDrag: ({ offset: [mx] }) => {
        set({ x: mx, hover: 1 })
      },
      onHover: ({ active }) => {
        set({ hover: active ? 1 : 0 })
      },
    },
    {
      drag: { bounds: { left: -width, right: 0 }, rubberband: true },
    }
  )

  return (
    <div id={id}>
      <animated.div
        {...bind()}
        className={`flex justify-start items-start cursor-move ${className} h-16`}
        ref={ref}
        style={{
          transform: x.interpolate(x => `translateX(${x}px)`),
          width: 20000,
          touchAction: "none",
        }}
      >
        <img
          src={`${variable.clients.url}`}
          className="select-none pointer-events-none h-full w-auto"
        />
      </animated.div>
    </div>
  )
}
