import React from "react"

export default function Steps({ block, id, key, className, type }) {
  return (
    <div id={id} className={`relative ${className}`}>
      <div className={`flex justify-center w-full flex-wrap my-6`}>
        {block.map((f, index) => (
          <div
            className={`flex-col justify-center my-3 mx-4 lg:w-1/3+l ${
              type === "BTC" ? `bg-lightPink` : `bg-lightBlue`
            } rounded-3xl`}
          >
            <div
              className={`flex justify-center italic font-bold flex-shrink-0 text-right my-2 ${
                type === "BTC" ? `text-pink` : ` text-blue`
              }`}
            >
              {f.Number}
            </div>
            <div className="flex-col text-center mx-8 my-2">
              <div className="text-lg font-bold mb-2">{f.title}</div>
              <div
                className={
                  f.description &&
                  "w-full text-sm lg:leading-normal leading-snug font-light"
                }
              >
                {f.description[0].children[0].text}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
