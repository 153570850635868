import React, { useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa"

export default function Sliders({ style, children, id }) {
  const sliderRef = useRef(null)
  const count = children.length > 3 ? true : false

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    //autoplay: true,
    //autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }

  return (
    <div id={id} className={`relative w-full ${style}`}>
      <button
        className="absolute inset-y-0 left-0 lg:-m-4 focus:outline-none"
        onClick={() => sliderRef?.current?.slickPrev()}
      >
        {count && <FaArrowCircleLeft className="text-3xl text-blue" />}
      </button>

      <Slider {...settings} ref={sliderRef}>
        {children}
      </Slider>
      <button
        className="absolute inset-y-0 right-0 lg:-m-4 focus:outline-none"
        onClick={() => sliderRef?.current?.slickNext()}
      >
        {count && <FaArrowCircleRight className="text-3xl text-blue" />}
      </button>
    </div>
  )
}
