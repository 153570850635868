import React, { useState } from "react"
import Help from "./Help"

export default function HelpWall({ faqContent, helps, id }) {
  if (!helps.length) return null

  return (
    <>
      <div id={id} className="relative lg:flex justify-center my-5 ">
        {faqContent && (
          <dl className="lg:w-1/2 flex flex-col items-center lg:justify-start pt-20">
            {faqContent.text.data.text}
          </dl>
        )}
        <dl
          className={
            faqContent
              ? `lg:w-1/2 w-full flex flex-col items-center justify-start pt-20`
              : `w-full flex flex-col items-center justify-start pt-20`
          }
        >
          {helps.map((help, index) => (
            <Help key={help.id} help={help} index={index} />
          ))}
        </dl>
      </div>
    </>
  )
}
